import React from "react";
import footerBg from "../../assets/images/footerbg.png";
import footerLady from "../../assets/images/footerMan.png";
import SectionHeading from "../Resuseables/Subheading/SectionHeading";
import Button from "../Resuseables/Button/Button";
import instagram from "../../assets/icons/Instagram.svg";
import linkedin from "../../assets/icons/LinkedIn.svg";
import twitter from "../../assets/icons/twitter.svg";
import DesktopLogo from "../svgs/DesktopLogo";
import { Link } from "react-router-dom";
import { navLinks } from "../../mock/data";

function Footer() {
  return (
    <footer className="relative w-full py-20">
      <div
        className="relative h-56 md:mt-32 lg:mt-64"
        style={{ backgroundImage: `url(${footerBg})` }}
      >
        <img
          src={footerLady}
          alt=""
          className="hidden md:block absolute bottom-0 z-10 w-[280px] lg:w-[387px]"
        />

        <div className=" absolute whitespace-nowrap top-1/2 left-1/2 md:left-2/3 xl:left-1/2 transform -translate-x-1/2 -translate-y-1/2  flex flex-col justify-center items-center text-white gap-10">
          <SectionHeading title={"The Possibilities Are Endless"} />

          <Link to={"/faq"}>
            <div>
              <Button
                btnText={"Get In Touch"}
                btnColor={"#0050ae"}
                btnBgColor={"#fff"}
                btnRadius="5px"
                btnPadding="8px 40px 8px 40px"
              />
            </div>
          </Link>
        </div>
      </div>

      {/* Links */}
      <div className="px-4 lg:px-28 py-10 flex flex-col md:flex-row justify-between gap-10 md:gap-4 ">
        <div className="">
          <div className="block">
            <DesktopLogo />
          </div>

          <ul className="flex gap-10 pt-4">
            {socialLinks.map((link, idx) => (
              <li key={idx}>
                <Link to={link.url}>
                  <img src={link.icon} alt="" />
                </Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="">
          <h3
            className="text-xl"
            style={{ color: "#FF0000", paddingBottom: "1rem" }}
          >
            Call Us
          </h3>
          <ul className="flex flex-col gap-4" style={{ color: "#0050ae " }}>
            {phones.map((item, idx) => (
              <li key={idx}>
                <a href={`tel:${item.call}`} style={{ textDecoration: "underline" }}>
                  {item.call}
                </a>
              </li>
            ))}
          </ul>
        </div>



        <div className="">
          <h3
            className="text-xl"
            style={{ color: "#FF0000", paddingBottom: "1rem" }}
          >
            Links
          </h3>

          <ul className="flex flex-col gap-4" style={{ color: "#0050ae " }}>
            {navLinks.map((item, idx) => (
              <li key={idx}>
                <Link to={item.url}>{item.label}</Link>
              </li>
            ))}
          </ul>
        </div>

        <div className="">
          <h3
            className="text-xl"
            style={{ color: "#FF0000", paddingBottom: "1rem" }}
          >
            Reach Out To Us
          </h3>
          <p className="max-w-[15rem]" style={{ color: "#0050ae" }}>
            For complaints or concerns, kindly send an email to&nbsp;
            <a
              href="mailto:Support@sleengshort.com"
              style={{ color: "#0050ae", textDecoration: "underline" }}
            >
              Support@sleengshort.com
            </a>
            &nbsp;or call any of our customer support lines.
          </p>

          <h3
            className="text-xl"
            style={{ color: "#FF0000", paddingBottom: "1rem", paddingTop: "1rem" }}
          >
            Address
          </h3>
          <p className="max-w-[15rem]" style={{ color: "#0050ae" }}>United Kingdom:</p>
          <p className="max-w-[15rem]" style={{ color: "#0050ae" }}>
            71-75 Shelton Street, Covent Garden, London, WC2H 9JQ
          </p>
        </div>
      </div>

      {/* Copyright */}
      <div className="w-full flex flex-col gap-10 md:flex-row justify-between px-4 lg:px-28 py-10">
        <p>&copy; 2023,Sleengshort. All rights reserved.</p>
        <p>
          {" "}
          Privacy Policy{" "}
          <span style={{ textDecoration: "underline" }}>
            {" "}
            Terms of Service{" "}
          </span>
        </p>
      </div>
    </footer>
  );
}

export default Footer;

export const socialLinks = [
  {
    id: 1,
    icon: instagram,
    url: "https://instagram.com/sleengshort.ng?igshid=YmMyMTA2M2Y=",
  },
  {
    id: 2,
    icon: linkedin,
    url: "https://www.linkedin.com/company/sleengshort-limited/",
  },
  {
    id: 3,
    icon: twitter,
    url: "https://twitter.com/sleengshort_ng?t=lD1uc4f-eAN5qMFobLc99Q&s=09",
  },
];

export const phones = [
  { id: 1, call: ": +234-811-415-9025" },

  { id: 2, call: ": +234-701-422-1574" },
];
